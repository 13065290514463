/* eslint-disable @nx/enforce-module-boundaries */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newPost, quotePost, updatePost } from 'v4/store/actions/post.actions';
import { useDisclosure } from '@mybridge/ui/hooks';
import { getAllCompanyPages } from 'v4/store/actions/companyPages.actions';
import { EditorState, CompositeDecorator, ContentState } from 'draft-js';
import { useToast } from '@mybridge/ui/hooks';
import { Box, CloseButton, HStack, Text } from '@mybridge/ui';
import { getTagUsersConnectionsData } from 'v4/store/actions/connection.actions';
import { useCurrentLocation } from 'v4/lib/hooks/use-location';
import { MyProfileContext } from '../profile/context';
export const PostFormContext = createContext({});

export const usePostFormContext = ({
  post,
  refetchAll,
  onCreateSuccess,
  onUpdateSuccess,
}) => {
  const [mode, setMode] = useState('create'); // create | edit
  const toast = useToast();
  const [multiPost, setMultiPost] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoObject, setVideoObject] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [gifsObject, setGifsObject] = useState([]);
  const [tagged, setTagged] = useState([]);
  const [visibility, setVisibility] = useState(0);
  const [replyControl, setReplyControl] = useState(0);
  const [companyId, setCompanyId] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [gifs, setGifs] = useState([]);
  const [moreOptionsActiveTab, setMoreOptionsActiveTab] = useState(null);
  const [feeling, setFeeling] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const [mediaUpdated, setMediaUpdated] = useState(false);
  const [publishingData, setPublishingData] = useState(false);
  const [multiCreateLoading, setMultiCreateLoading] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [thumbnailData, setThumbnailData] = useState(null);
  const [text, setText] = useState('');
  const [isReactionMenuOpen, setIsReactionMenuOpen] = useState(true);
  const [isControlMenuOpen, setIsControlMenuOpen] = useState(false);
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [emojis, setEmojis] = useState([]);
  // const location = useCurrentLocation({ enabled: true, searchPlace: false });
  const {
    userProfileInfo,
    refetchPhotosOfyou,
    refetchYourPhotos,
    refetchyourVideos,
    refetchvideosOfYou,
    selectedImgPost,
  } = useContext(MyProfileContext);
  const MAX_CHAR_COUNT = 250;
  const { companyPageDetail } = useSelector((state) => state.companyPage);
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const { allDegreeConnections, taggedListUsers } = useSelector(
    (state) => state.connection
  );
  const { companyPageList } = useSelector((state) => state.companyPage);
  const { feelingsAndActivities } = useSelector((s) => s.globalData) ?? {};

  const [agreeDisAgree, setAgreeDisAgree] = useState(true);
  const [canComment, setCanComment] = useState(true);

  const textInputRef = useRef();
  const formContainerRef = useRef();
  const fileInputRef = useRef();
  const newFileInputRef = useRef();
  const moreOptionsDisc = useDisclosure();
  const publishArticleDisc = useDisclosure();
  const videoProcessingDisc = useDisclosure();

  // Function to find the exceeding text
  const findExceedingText = (contentBlock, callback, contentState) => {
    const hasText = contentBlock.getKey();
    const text = contentBlock.getText();
    // console.log('text', hasText);
    if (text.length > 250) {
      callback(250, text.length);
    } else {
      setText?.(text);
    }
  };

  // Component to highlight the exceeding text
  const ExceedingText = (props) => {
    return (
      <span style={{ backgroundColor: '#FAD2D1', color: 'white' }}>
        {props.children}
      </span>
    );
  };
  const decorator = new CompositeDecorator([
    {
      strategy: findExceedingText,
      component: ExceedingText,
    },
  ]);

  const EditorDefaultState = EditorState.createEmpty(decorator);

  const [postEditorData, setPostEditorData] = useState(EditorDefaultState);
  const [shouldChange, setShouldChange] = useState(false);
  const [isFromProfileFlag, setisFromProfileFlag] = useState(false);
  useEffect(() => {
    if (post?.id) {
      setMode('edit');
      setExpanded(true);
      const { content, gif, media, tagged_users, feelings } = post ?? {};
      if (feelings?.id) {
        setFeeling(feelings);
      }
      if (content?.length) {
        setPostEditorData(
          EditorState.createWithContent(ContentState.createFromText(content))
        );
        setText(content);
      }
      if (tagged_users?.length) {
        setTagged(tagged_users);
      }
      if (gif?.length) {
        setGifs([
          {
            images: { original: { url: gif } },
          },
        ]);
      }
      if (media?.length) {
        const hasVid = media?.find?.((m) =>
          m?.file?.toLowerCase?.()?.includes?.('mp4')
        );
        if (hasVid) {
          setVideos([media?.[0]?.file]);
          setVideoObject(media?.[0]);
        } else {
          setImageObject(media);
          setImages(media?.map?.((m) => m?.file));
        }
      }
      setVisibility(post?.visibility);
      setReplyControl(post?.reply_control);
    }
  }, [post, post?.media?.[0]?.file]);
  console.log(imageObject, 'imageObjecttttt');
  console.log(typeof images?.[0], 'imageObjecttttt2');
  useEffect(() => {
    if (mediaUpdated) {
      console.log(mediaUpdated);
    }
  }, [mediaUpdated]);

  useEffect(() => {
    if (!companyPageList?.length) {
      dispatch(getAllCompanyPages());
    }
    dispatch(getTagUsersConnectionsData());
  }, []);

  const addImage = (file) => {
    images.push(file);
    setMediaUpdated(true);
    setImages([...images]);
    setImageObject([...imageObject]);
  };

  const removeImage = (file, index) => {
    if (file === 'editMode') {
      setMediaUpdated(true);
    } else {
      const images_ = images?.filter((_, ind) => ind !== index);
      const imagesObject_ = imageObject?.filter((_, ind) => ind !== index);
      setMediaUpdated(true);
      setImages(images_);
      setImageObject(imagesObject_);
    }
  };

  const addGif = (file) => {
    gifs.push(file);
    setMediaUpdated(true);
    setGifs([...gifs]);
  };

  const removeGif = (file, index) => {
    const gifs_ = gifs?.filter((_, ind) => ind !== index);
    setGifs(gifs_);
  };

  const addVideo = (file) => {
    videos.push(file);
    setMediaUpdated(true);
    setVideos([...videos]);
  };

  const removeVideo = (file, index) => {
    const videos_ = videos?.filter((_, ind) => ind !== index);
    setMediaUpdated(true);
    setVideos(videos_);
  };

  const companyPosting = (company) => {
    setCompanyId(company?.id);
  };

  const convertAndUploadThumbnail = async (url) => {
    if (!url) return;

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (blob) {
          setThumbnailData(blob);
        }
      }, 'image/png');
    };
    img.onerror = () => {
      console.error('Failed to load image.');
    };
  };

  const { mutate: createPost, isLoading: createLoading } = useMutation(
    async ({ body, isFromProfile }) => {
      setisFromProfileFlag(isFromProfile);
      return dispatch(newPost(body));
    },
    {
      onSuccess: (data) => {
        onCreateSuccess?.(data, !!videos?.length);
        resetForm();
        setCloseModal(true);
        if (!videos.length) {
          refetchAll?.();
        } else if (videos.length) {
          console.log(userProfileInfo, 'isFromProfileFlag');
          setTimeout(() => {
            if (isFromProfileFlag) {
              refetchPhotosOfyou?.({ slug: userProfileInfo.slug });
              refetchYourPhotos?.({ user: userProfileInfo.slug });
              refetchyourVideos?.({ user: userProfileInfo.slug });
              refetchvideosOfYou?.({ user: userProfileInfo.slug });
            } else {
              refetchAll?.();
            }

            // dispatch(fetchPosts({ location }));

            toast?.({
              title: 'Your post is now ready',
              status: 'success',
              position: 'top',
              duration: 5000,
            });
          }, 20000);
        }
        if (videos?.length) {
          toast({
            position: 'top-right',
            // status: 'success',
            render: () => (
              <Box
                color="#5B5B5B"
                p={3}
                bg="white"
                boxShadow="0px 0px 10px 0px #00000033"
              >
                <HStack justifyContent="space-between">
                  <Text flex="1">
                    Your post is being processed. We’ll let you know when it’s
                    ready to view.
                  </Text>
                  <CloseButton />
                </HStack>
              </Box>
            ),
            duration: 21000,
            isClosable: true,
          });
        }
      },
      onError: (error) => {
        setCloseModal(true);
        console.log(error);
      },
    }
  );

  const createMultiPost = async (body) => {
    setMultiCreateLoading(true);
    let parent_post_id = null;
    for (const post of body) {
      const formData = new FormData();
      // if posText is more than 300 characters then return
      if (post?.text?.length > 300) {
        return;
      }
      if (post?.companyId) {
        formData.append('company_id', post?.companyId);
      }
      formData.append('visibility', post?.visibility || 0);
      formData.append('reply_control', post?.replyControl || 0);

      if (post?.text?.length > 0) {
        formData.append('content', post?.text);
      }

      if (post?.feeling?.id) {
        formData.append('feelings_id', post?.feeling?.id);
      }

      if (post?.tagged && post?.tagged?.length) {
        post?.tagged?.forEach?.((t) =>
          formData.append('tagged_user_ids', t?.id)
        );
      }

      if (post?.images?.length) {
        post?.images.forEach((image) => {
          formData.append('media', image);
        });
      }

      formData.append('can_comment', post?.canComment || true);
      formData.append('can_agree', post?.agreeDisAgree || true);

      if (post?.gifs?.length) {
        formData.append('gif', post?.gifs?.[0]?.images?.original?.url);
      }

      if (post?.videos?.length) {
        formData.append('media', post?.videos?.[0]);
      }
      if (parent_post_id) {
        formData.append('parent_post', parent_post_id);
      }

      try {
        setCloseModal(true);
        const response = await dispatch(newPost(formData));
        if (response?.payload?.post_id && parent_post_id === null) {
          parent_post_id = response?.payload?.post_id;
        }
      } catch (error) {
        setCloseModal(true);
        console.log(error);
      }
    }
    setMultiCreateLoading(false);
    setMultiPost([]);
    resetForm();
    refetchAll?.();
  };

  const editMultiPost = async (body) => {
    setMultiCreateLoading(true);
    let parent_post_id = null;
    for (const post of body) {
      const formData = new FormData();
      // if posText is more than 300 characters then return
      if (post?.content?.length > 300) {
        return;
      }
      if (post?.id && !parent_post_id) {
        parent_post_id = post?.id;
      }
      if (post?.companyId) {
        formData.append('company_id', post?.companyId);
      }
      formData.append('visibility', post?.visibility || 0);
      formData.append('reply_control', post?.replyControl || 0);

      if (post?.content?.length > 0) {
        formData.append('content', post?.content);
      }

      if (post?.feeling?.id) {
        formData.append('feelings_id', post?.feelings?.id);
      }

      if (post?.tagged && post?.tagged?.length) {
        post?.tagged?.forEach?.((t) =>
          formData.append('tagged_user_ids', t?.id)
        );
      }

      if (post?.media?.length) {
        post?.media?.forEach((media) => {
          formData.append('media', media);
        });
      } else {
        formData.append('media', post?.media);
      }

      formData.append(
        'can_comment',
        post?.canComment !== undefined ? post?.canComment : true
      );
      formData.append(
        'can_agree',
        post?.agreeDisAgree !== undefined ? post?.agreeDisAgree : true
      );

      if (post?.gifs?.length) {
        formData.append(
          'gif',
          post?.gifs?.[0]?.images?.original?.url || post?.gifs[0]
        );
      }

      if (post?.videos?.length) {
        formData.append('media', post?.media?.[0]);
      }
      if (parent_post_id) {
        formData.append('parent_post', parent_post_id);
      }

      formData.append('media_updated', mediaUpdated);
      if (!mediaUpdated) {
        formData.delete('media');
      }

      try {
        setCloseModal(true);
        // await dispatch(updatePost({ formData: formData, id: post?.id }));
        if (!!post?.id) {
          await dispatch(updatePost({ formData: formData, id: post?.id }));
        } else if (!post?.id) {
          await dispatch(newPost(formData));
        }
      } catch (error) {
        setCloseModal(true);
        console.log(error);
      }
    }
    setMultiCreateLoading(false);
    setMultiPost([]);
    resetForm();
    refetchAll?.();
  };

  // const editMultiPost = async (body) => {
  //   console.log(body, 'posttttttttttttt');
  //   setMultiCreateLoading(true);
  //   let parent_post_id = null;
  //   for (const post of body) {
  //     const formData = new FormData();
  //     // if posText is more than 300 characters then return
  //     if (post?.content?.length > 300) {
  //       return;
  //     }
  //     if (post?.id && !parent_post_id) {
  //       parent_post_id = post?.id;
  //     }
  //     if (post?.companyId) {
  //       formData.append('company_id', post?.companyId);
  //     }
  //     formData.append('visibility', post?.visibility || 0);
  //     formData.append('reply_control', post?.replyControl || 0);

  //     if (post?.content?.length > 0) {
  //       formData.append('content', post?.content);
  //     }

  //     if (post?.feeling?.id) {
  //       formData.append('feelings_id', post?.feelings?.id);
  //     }

  //     if (post?.tagged && post?.tagged?.length) {
  //       post?.tagged?.forEach?.((t) =>
  //         formData.append('tagged_user_ids', t?.id)
  //       );
  //     }

  //     if (post?.media?.length) {
  //       post?.media?.forEach((media) => {
  //         if (typeof media != 'string') {
  //           formData.append('media', media);
  //         }
  //       });
  //     } else {
  //       typeof media != 'string' && formData.append('media', post?.media);
  //     }

  //     formData.append(
  //       'can_comment',
  //       post?.canComment !== undefined ? post?.canComment : true
  //     );
  //     formData.append(
  //       'can_agree',
  //       post?.agreeDisAgree !== undefined ? post?.agreeDisAgree : true
  //     );
  //     // if (post?.media?.length !== imageObject?.length && mediaUpdated) {
  //     //   formData.append('media_ids', imageObject);
  //     //   formData.delete('media');
  //     //   post?.media?.forEach((media) => {
  //     //     if (typeof media !== 'string') {
  //     //       formData.append('media_ids', media?.id);
  //     //     }
  //     //   });
  //     //   console.log(post, 'post?.media');
  //     // }

  //     // if (post?.media?.length) {
  //     //   post?.media?.forEach((media) => {
  //     //     // If the media is not a string (i.e., it's a new File), append it
  //     //     if (typeof media !== "string") {
  //     //       if (media instanceof File) {
  //     //         media?.id&&formData.append('media_ids', media?.id);
  //     //       }
  //     //     }
  //     //   });
  //     // }
  //     if (post?.gifs?.length) {
  //       formData.append(
  //         'gif',
  //         post?.gifs?.[0]?.images?.original?.url || post?.gifs[0]
  //       );
  //     formData.append('media_updated',mediaUpdated);

  //     }

  //     if (post?.videos?.length) {
  //       formData.append('media', post?.media?.[0]);
  //     formData.append('media_updated',mediaUpdated);
  //     }
  //     if (parent_post_id) {
  //       formData.append('parent_post', parent_post_id);
  //     }

  //     // formData.append('media_updated',mediaUpdated);
  //     if (!mediaUpdated ||(!post?.media?.length&& !post?.videos?.length)) {
  //       formData.delete('media');
  //       formData.delete('media_updated');
  //     }
  //     console.log(post, 'post?.media');
  //     try {
  //       setCloseModal(true);
  //       // await dispatch(updatePost({ formData: formData, id: post?.id }));
  //       if (!!post?.id) {
  //         await dispatch(updatePost({ formData: formData, id: post?.id }));
  //       } else if (!post?.id) {
  //         await dispatch(newPost(formData));
  //       }
  //     } catch (error) {
  //       setCloseModal(true);
  //       console.log(error);
  //     }
  //   }
  //   setMultiCreateLoading(false);
  //   setMultiPost([]);
  //   resetForm();
  //   refetchAll?.();
  // };

  const { mutate: quotePostMutate, isLoading: quotePostLoading } = useMutation(
    async (body) => {
      return dispatch(quotePost(body));
    },
    {
      onSuccess: (data) => {
        resetForm();
        refetchAll?.();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: editPost, isLoading: editLoading } = useMutation(
    async (body) => {
      return dispatch(updatePost({ id: post?.id, formData: body }));
    },
    {
      onSuccess: (data) => {
        resetForm();
        refetchAll?.();
        onUpdateSuccess?.(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleSubmit = async (lastPost, isFromProfile) => {
    const formData = new FormData();
    // if posText is more than 300 characters then return
    if (text?.length > 300) {
      return;
    }
    if (companyId) {
      formData.append('company_id', companyId);
    }
    formData.append('visibility', visibility);
    formData.append('reply_control', replyControl);

    if (text?.length > 0) {
      formData.append('content', text);
    }

    if (feeling?.id) {
      formData.append('feelings_id', feeling?.id);
    }

    if (tagged && tagged?.length) {
      tagged?.forEach?.((t) => formData.append('tagged_user_ids', t?.id));
    }

    if (images?.length) {
      images.forEach((image) => {
        formData.append('media', image);
      });
    }

    formData.append('can_comment', canComment);
    formData.append('can_agree', agreeDisAgree);

    if (gifs?.length) {
      formData.append('gif', gifs?.[0]?.images?.original?.url);
    }

    if (thumbnailData && gifs?.length) {
      formData.append(
        'video_thumbnail',
        thumbnailData,
        `${gifs?.[0]?.slug}.png`
      );
    }

    if (videos?.length) {
      formData.append('media', videos?.[0]);
      const thumbnail = await generateVideoThumbnail(videos?.[0]);
      let fileName = videos?.[0]?.name;
      // console.log('thumbnail', thumbnail);
      formData.append(
        'video_thumbnail',
        thumbnail,
        `${fileName?.substring(0, fileName.lastIndexOf('.'))}.png`
      );
    }
    // console.log('formData', videos, JSON.stringify(Object.fromEntries(formData)));
    if (mode === 'create' && multiPost.length === 0) {
      createPost({ body: formData, isFromProfile });
    } else if (mode === 'create' && multiPost.length > 0) {
      createMultiPost([...multiPost, lastPost]);
    } else if (mode === 'edit' && multiPost.length <= 1) {
      formData.append('media_updated', mediaUpdated);
      if (!mediaUpdated) {
        formData.delete('media');
      }
      editPost(formData);
    } else if (mode === 'edit' && multiPost.length > 1) {
      editMultiPost([...multiPost, lastPost]);
    }
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(b64toBlob(canvas.toDataURL('image/png')));
      };
    });
  };

  const b64toBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  };

  const submitPost = (lastPost, isFromProfile) => {
    console.log(lastPost, 'lastPostlastPostsss');
    handleSubmit(lastPost, isFromProfile);
  };

  const submitQuotePost = () => {
    quotePostMutate({
      id: post?.id,
      content: text,
    });
  };

  const setAgreeDisAgreeVisibility = (e) => {
    setAgreeDisAgree(e);
  };

  const setCanCommentVisibility = (e) => {
    setCanComment(e);
  };

  // const onReactionClick = () => {
  //   setReactionModalOpen(true);
  // }

  const resetForm = () => {
    setImages([]);
    setImageObject([]);
    setGifs([]);
    setVideos([]);
    setTagged([]);
    setText('');
    setReplyControl(0);
    setVisibility(0);
    setCloseModal(false);
    setShouldChange(true);
    setPostEditorData(EditorDefaultState);
    setFeeling(null);
    if (mode === 'edit') {
      setMediaUpdated(false);
    }
  };

  const handleMediaFilesChange = (e) => {
    const { files } = e?.target ?? {};
    const filesArr = Array.from(files);
    const video = filesArr?.find?.((f) => f.type?.startsWith('video/'));
    if (video) {
      setVideoObject(video);
      setVideos([video]);
      setImages([]);
      setImageObject([]);
    } else {
      const endIndex = 5 - images.length;
      setImages([
        ...(images ?? []),
        ...filesArr.slice(0, Math.min(filesArr.length, endIndex)),
      ]);
      setImageObject([
        ...(imageObject ?? []),
        ...filesArr.slice(0, Math.min(filesArr.length, endIndex)),
      ]);
      setVideos([]);
      setVideoObject([]);
    }
    setMediaUpdated?.(true);
    setTimeout(() => {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      } else if (newFileInputRef.current) {
        newFileInputRef.current.value = null;
      }
    }, 100);
  };

  const hasContent = useMemo(() => {
    return (
      text?.length > 0 ||
      images?.length > 0 ||
      videos?.length > 0 ||
      gifs?.length > 0
    );
  }, [text, images, videos, gifs]);

  return {
    text,
    setText,
    images,
    setImages,
    videos,
    setVideos,
    gifs,
    setGifs,
    textInputRef,
    addImage,
    removeImage,
    addVideo,
    removeVideo,
    addGif,
    removeGif,
    replyControl,
    closeModal,
    setReplyControl,
    setCloseModal,
    visibility,
    setVisibility,
    textMaxLength: 250,
    submitPost,
    isLoading: createLoading || editLoading || multiCreateLoading,
    expanded,
    setExpanded,
    formContainerRef,
    hasContent,
    mode,
    setMode,
    setMediaUpdated,
    mediaUpdated,
    quotePost: submitQuotePost,
    quotePostLoading,
    companyPosting,
    moreOptionsActiveTab,
    setMoreOptionsActiveTab,
    moreOptionsDisc,
    feeling,
    setFeeling,
    allDegreeConnections,
    tagged,
    setTagged,
    feelingsAndActivities,
    handleMediaFilesChange,
    fileInputRef,
    newFileInputRef,
    publishArticleDisc,
    publishingData,
    setPublishingData,
    agreeDisAgree,
    setAgreeDisAgreeVisibility,
    canComment,
    setCanCommentVisibility,
    // onClose,
    // onReactionClick
    multiPost,
    setMultiPost,
    setImageObject,
    imageObject,
    resetForm,
    isDisable,
    setIsDisable,
    postEditorData,
    setPostEditorData,
    EditorDefaultState,
    isCompany,
    setIsCompany,
    isReactionMenuOpen,
    setIsReactionMenuOpen,
    isControlMenuOpen,
    setIsControlMenuOpen,
    setShouldChange,
    shouldChange,
    thumbnailData,
    setThumbnailData,
    convertAndUploadThumbnail,
    openMoreOptions,
    setOpenMoreOptions,
    setEmojis,
    emojis,
    taggedListUsers,
  };
};
